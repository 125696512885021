@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500&display=swap");

.cardcontainer {
  /* width: 200px; */
  padding-right: 20px;
  height: 100px;
  background: white;
  display: flex;
  /* justify-content: center; */

  align-items: center;
  margin: 10px;
  border-radius: 20px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.cardcontainerleft img {
  width: 50px;
  height: 50px;
  border-radius: 100px;
}

.cardcontainerleft {
  margin-left: 15px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.cardcontainerright {
  margin-left: 10px;
}

.cardcontainerright h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 20px;
  width: fit-content;
  /* background: red; */
  white-space: nowrap;
  width: 100%;
}

.cardcontainerright h2 {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  margin-top: -2px;
}
