@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap");

.pricing_screen_container {
  /* padding: 50px; */
  /* background-color: rgb(228, 143, 143); */
  padding-top: 30px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pricing_screen_container_main {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  width: 100vw;
  /* height: 100vh; */
  /* background: chartreuse; */
  overflow-x: hidden;
}
.pricing_screen_container {
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  /* background: chartreuse; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* height: 100vh; */
}

.pricing_screen_container_head {
  /* background: cornsilk; */
  /* padding-top: 30px; */
  padding-bottom: 30px;
  padding-top: 50px;
}

.pricing_screen_container_bars {
  padding-bottom: 100px;
}

@media screen and (max-width: 750px) {
  .pricing_screen_container_head h1 {
    font-size: 30px;
  }
}

@media screen and (max-width: 550px) {
  .pricing_screen_container_head h1 {
    font-size: 20px;
  }
}
