@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500&display=swap");

.menucontainermain {
  /* padding-bottom: 100px; */
  /* height: 100%; */
  /* background: red; */
  width: 100vw;
  overflow-x: hidden;
}
.container1 {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.ourmenu {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 10px;
  justify-content: center;
  align-items: center;
  /* width: 100vw; */
  display: flex;
}
.ourmenu h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  width: fit-content;
  font-size: 35px;
  border-bottom: 3px solid red;
}

.container1cards {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  width: 100vw;
}

.container1cards::-webkit-scrollbar {
  display: none;
}

.container1 h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 20px;
}

.container1 h2 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 20px;
}

.menucat {
  margin: 10px;
  font-size: 45px;
  color: rgb(255, 0, 0);
}

.menutype {
  margin: 10px;
}

.footercontainer {
  margin-top: 50px;
}
