@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500&display=swap");

.quick {
  height: 100%;
  width: 100%;
  align-items: center;
  /* justify-content: center; */
  display: flex;
  flex-direction: column;
  background-image: url("https://images.pexels.com/photos/1600711/pexels-photo-1600711.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  overflow-x: hidden;
}

.quicknav {
  width: 100%;
}
.quickh1 {
  margin-top: 7vh;
}

.quickh1 h1 {
  /* margin-top: 10vh; */
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  border-bottom: 3px solid rgb(255, 102, 0);
}

.quick1 {
  width: 350px;
  height: 400px;
  /* padding-bottom: 30px; */
  padding-top: 10px;
  background: white;
  border-radius: 20px;
  margin: 10px;
  text-align: center;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.quick1h1 {
  margin-top: 15px;
}
.quick1 h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 20px;
}

.quick1txt {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* margin-top: 10px; */
  margin-top: 20px;
  align-items: center;
}

.quick1txt h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 15px;
  margin-left: 20px;
  /* background: chartreuse; */
  text-align: start;
  width: fit-content;
  max-width: 150px;
}

.quick1txt h2 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 15px;
  margin-right: 20px;
}

/* Quick2 */

.quickh2 {
  margin-top: 10vh;
}

.quickh2 h1 {
  /* margin-top: 10vh; */
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.quick2 {
  width: 300px;
  height: 350px;
  /* padding-bottom: 30px; */
  padding-top: 10px;
  background: white;
  border-radius: 20px;
  margin: 10px;
  text-align: center;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.quick2h1 {
  margin-top: 15px;
}
.quick2 h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 20px;
}

.quick2txt {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* margin-top: 10px; */
  margin-top: 20px;
  align-items: center;
}

.quick2txt h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 15px;
  margin-left: 20px;
  /* background: chartreuse; */
  text-align: start;
  width: fit-content;
  max-width: 150px;
}

.quick2txt h2 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 15px;
  margin-right: 20px;
}

/* Quick3 */

.quick3 {
  width: 300px;
  height: 350px;
  background: white;
  border-radius: 20px;
  margin: 10px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

/* Quick2 */
.quick2 {
  width: 300px;
  height: 350px;
  background: white;
  border-radius: 20px;
  margin: 10px;
}

.quickh3 {
  margin-top: 10vh;
}

.quickh3 h1 {
  /* margin-top: 10vh; */
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.quick3 {
  width: 300px;
  height: 350px;
  padding-top: 10px;
  background: white;
  border-radius: 20px;
  margin: 10px;
  text-align: center;
}

.quick3h1 {
  margin-top: 15px;
}
.quick3 h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 20px;
}

.quick3txt {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* margin-top: 10px; */
  margin-top: 20px;
  align-items: center;
}

.quick3txt h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 15px;
  margin-left: 20px;
  /* background: chartreuse; */
  text-align: start;
  width: fit-content;
  max-width: 150px;
}

.quick3txt h2 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 15px;
  margin-right: 20px;
}

.quickcards {
  display: flex;
  flex-direction: row;
  margin-top: 5vh;
  flex-wrap: wrap;
  /* background: red; */
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
}

.footer {
  width: 100%;
  margin-top: 150px;
}

@media only screen and (max-width: 500px) {
  .footer {
    width: 100%;
    margin-top: 30px;
  }
  .quickh1 h1 {
    /* margin-top: 10vh; */
    font-size: 25px;
  }
}

@media only screen and (max-width: 1000px) {
  .quick {
    background-image: none;
  }
}
