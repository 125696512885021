@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500&display=swap");

.ratepage {
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
  align-items: center;
  width: 100vw;
}

.ratecontainer {
  width: 500px;
  height: 300px;
  background: white;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  border-radius: 50px;
}

.ratecontainer button {
  margin-top: 15px;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border: none;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: black;
  border: 10px;
  background: rgb(2, 103, 255);
  color: white;
  border-radius: 15px;
}

.ratecontainer h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: black;
  margin-bottom: 15px;
}
