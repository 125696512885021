@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500&display=swap");
.navbarlogoimg {
  width: 130px;
  z-index: 1111111;
}

.navbarcontainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #fafbca;
  height: 70px;
  width: 100%;
  border-bottom: 1px solid rgb(199, 191, 191);
}

.navbarcontainerright ul {
  display: flex;
  flex-direction: row;
  margin-right: 10vw;
}

.link {
  margin-right: 30px;
  list-style: none;
  border-bottom: 3px solid #fafbca;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: black;
  text-decoration: none;
}

.link:hover {
  cursor: pointer;
  border-bottom: 3px solid #ff0505;
}

.navbarcontainerleft {
  margin-left: 50px;
}

@media screen and (max-width: 400px) {
  .link {
    font-size: 12px;
    margin-right: 0px;
    margin: 10px;
  }
  .navbarcontainerleft {
    margin-left: 0px;
  }
  .navbarcontainerright ul {
    display: flex;
    flex-direction: row;
    margin-right: 0vw;
  }
}

@media screen and (max-width: 900px) {
  .navbarlogoimg {
    display: none;
    /* width: 50px; */
  }

  .navbarcontainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .navbarcontainerright ul {
    display: flex;
    flex-direction: row;
    margin-right: 0vw;
  }
}

.active {
  color: #fcfcfc;
}
