.back_container {
  height: 70px;
  background-color: #fafbca;
  /* align-items: center; */
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 100vw;
}

.icon_button {
  /* margin-left: 100px; */
  position: relative;
  width: 50px;
}

.back_icon {
  color: #000000;
  /* margin-left: 50px; */
}
