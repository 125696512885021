@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500&display=swap");
.footer_container2 {
  display: flex;
  justify-content: space-evenly;
}
.footer_container2 ul li {
  list-style: none;
}

.footer {
  height: 400px;
  position: relative;
  bottom: 0px;

  background: #eb3a3a;
  /* justify-content: center; */
  display: flex;
  flex-direction: column;
}

.footer_container2_main {
  /* background-color: azure; */
  /* align-items: flex-start; */
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.footer_container2_main ul {
  /* align-self: flex-start; */
  /* position: relative; */
  background-color: blueviolet;
  /* width: fit-content; */
  justify-content: start;
  align-content: flex-start;
  display: flex;
  flex-direction: column;
}
.footer_container2_main ul li {
  /* align-self: flex-start; */

  display: flex;
  flex-direction: column;
  /* background-color: brown; */
  left: 0px;
}
.footer_container2_main li {
  list-style: none;
}

.heading {
  font-size: 35px;
  color: aquamarine;
}

.footer_logo_container {
  /* background: #501150; */
  align-items: center;
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  justify-content: center;
  align-items: center;
  /* width: 100vw; */
  /* background-color: rgb(255, 255, 255); */
}

.footer_logo_container h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 40px;
  color: rgb(66, 18, 18);
}
.footerimg {
  width: 150px;
  /* background-color: white; */
  /* border-radius: 100px; */
  /* padding: 10px; */
}

.footer_container2_main .lists {
  /* margin-top: -10px; */
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: rgb(245, 237, 237);
}

.footer_container2_main .lists li {
  margin-top: 5px;
  max-width: 250px;
  font-weight: 400;
  border-bottom: 2px solid #eb3a3a;
  width: fit-content;
}

.footer_container2_main .lists li:hover {
  border-bottom: 2px solid white;
  cursor: pointer;
}
.footer_container2_main h1 {
  font-family: "Poppins", sans-serif;
  color: white;
  font-weight: 500;
  font-size: 30px;
}

.links li {
  border-bottom: 1px solid #2374bf;
  width: fit-content;
}
.links li:hover {
  cursor: pointer;
  border-bottom: 1px solid rgb(255, 255, 255);
}

.connect_with_us {
  /* background: yellow; */
  padding-top: 60px;
}
.connect_with_us h1 {
  font-family: "Poppins", sans-serif;
  color: white;
  font-weight: 400;
  font-size: 20px;
  text-align: center;
}

.copyright {
  padding-top: 20px;
  padding-bottom: 20px;
  /* background: yellow; */
  /* margin-top: 70px; */
}

.copyright h1 {
  font-family: "Poppins", sans-serif;
  color: white;
  font-weight: 400;
  font-size: 15px;
  text-align: center;
  /* margin-top: -10px; */
}

.icons {
  /* background: blueviolet; */
  width: fit-content;
  display: flex;
  margin-top: 10px;

  /* margin-top: -10px; */
  justify-content: center;
  /* background-color: white; */
  /* width: 100vw; */

  justify-content: center;
  align-items: center;
}

.lastsection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-color: blue; */
  position: absolute;
  width: 100vw;
  bottom: 0px;
}

.link {
  text-decoration: none;
  color: white;
}

@media screen and (max-width: 900px) {
  .footer_container2 {
    flex-direction: column;
    padding-left: 20px;
  }
  .footer {
    height: 950px;
  }
}
