@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500&display=swap");

.drink {
  height: 100%;
  width: 100%;
  align-items: center;
  /* justify-content: center; */
  display: flex;
  flex-direction: column;
  background-image: url("https://images.unsplash.com/photo-1551024709-8f23befc6f87?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1257&q=80");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  overflow-x: hidden;
}

.drinknav {
  width: 100%;
}
.drinkh1 {
  margin-top: 7vh;
}

.drinkh1 h1 {
  /* margin-top: 10vh; */
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  border-bottom: 3px solid rgb(255, 102, 0);
}

.drink1 {
  width: 300px;
  height: 410px;
  /* padding-bottom: 30px; */
  padding-top: 10px;
  background: white;
  border-radius: 20px;
  margin: 10px;
  text-align: center;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.drink1h1 {
  margin-top: 15px;
}
.drink1 h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 20px;
}

.drink1txt {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* margin-top: 10px; */
  margin-top: 20px;
  align-items: center;
}

.drink1txt h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 15px;
  margin-left: 20px;
  /* background: chartreuse; */
  text-align: start;
  width: fit-content;
  max-width: 150px;
}

.drink1txt h2 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 15px;
  margin-right: 20px;
}

/* drink2 */

.drinkh2 {
  margin-top: 10vh;
}

.drinkh2 h1 {
  /* margin-top: 10vh; */
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.drink2 {
  width: 300px;
  height: 410px;
  /* padding-bottom: 30px; */
  padding-top: 10px;
  background: white;
  border-radius: 20px;
  margin: 10px;
  text-align: center;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.drink2h1 {
  margin-top: 15px;
}
.drink2 h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 20px;
}

.drink2txt {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* margin-top: 10px; */
  margin-top: 20px;
  align-items: center;
}

.drink2txt h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 15px;
  margin-left: 20px;
  /* background: chartreuse; */
  text-align: start;
  width: fit-content;
  max-width: 150px;
}

.drink2txt h2 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 15px;
  margin-right: 20px;
}

/* drink3 */

.drink3 {
  width: 300px;
  height: 350px;
  background: white;
  border-radius: 20px;
  margin: 10px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

/* drink2 */
.drink2 {
  width: 300px;
  height: 410px;
  background: white;
  border-radius: 20px;
  margin: 10px;
}

.drinkh3 {
  margin-top: 10vh;
}

.drinkh3 h1 {
  /* margin-top: 10vh; */
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.drink3 {
  width: 300px;
  height: 410px;
  padding-top: 10px;
  background: white;
  border-radius: 20px;
  margin: 10px;
  text-align: center;
}

.drink3h1 {
  margin-top: 15px;
}
.drink3 h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 20px;
}

.drink3txt {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* margin-top: 10px; */
  margin-top: 20px;
  align-items: center;
}

.drink3txt h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 15px;
  margin-left: 20px;
  /* background: chartreuse; */
  text-align: start;
  width: fit-content;
  max-width: 150px;
}

.drink3txt h2 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 15px;
  margin-right: 20px;
}

.drinkcards {
  display: flex;
  flex-direction: row;
  margin-top: 5vh;
  flex-wrap: wrap;
  /* background: red; */
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
}

.footer {
  width: 100%;
  margin-top: 150px;
}

@media only screen and (max-width: 500px) {
  .footer {
    width: 100%;
    margin-top: 30px;
  }
  .drinkh1 h1 {
    /* margin-top: 10vh; */
    font-size: 25px;
  }
}

@media only screen and (max-width: 1000px) {
  .drink {
    background-image: none;
  }
}
