@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500&display=swap");

.featurecontainer {
  overflow-x: hidden;
}
.featurecontainer1 img {
  width: 450px;
  border-radius: 100px;
}

.featurecontainer1 {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  height: 80vh;
  align-items: center;
  /* background: url("https://images.pexels.com/photos/326279/pexels-photo-326279.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1");
  background-repeat: no-repeat;
  background-size: 100% 90vh; */
}

.featurecontainer1 h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: black;
  font-size: 45px;
  width: 250px;
}

.featurecontainer2 {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  /* background: red; */
}

.featuresh1 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  border-bottom: 2px solid red;
  width: fit-content;
}

.featurecontainer2cards {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  /* height: 100vh; */
  height: 400px;
  /* background: chartreuse; */
  width: 100vw;
  align-items: center;
}

.card1container1 h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 25px;
  margin-top: 10px;
}
.img1 {
  width: 140px;
}
.img2 {
  width: 100px;
  margin-top: -25px;
}

.img3 {
  width: 120px;
}

.img4 {
  width: 147px;
}

.img5 {
  width: 195px;
}

.img6 {
  width: 250px;
}
.featurecontainer1txt {
  margin-left: 35px;
}
@media screen and (max-width: 800px) {
  .featurecontainer1txt h1 {
    font-size: 35px;
    margin-left: 0px;
  }
}

@media screen and (max-width: 800px) {
  /* .featurecontainer1 {
    display: flex;
    flex-direction: column;
  } */

  .featurecontainer1 {
    display: flex;
    flex-direction: row;
    /* justify-content: space-evenly; */
    height: 300px;
    /* align-items: center; */
    /* background: url("https://images.pexels.com/photos/326279/pexels-photo-326279.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1");
    background-repeat: no-repeat;
    background-size: 100% 300px; */
    flex-direction: column-reverse;
    height: 400px;
  }

  .featurecontainer2cards {
    display: flex;
    flex-direction: column;
    height: 800px;
  }
  .featurecontainer1 img {
    width: 200px;
  }
}
