.aboutmaincontainer {
  width: 100vw;
  /* overflow-x: h; */
  overflow-x: hidden;
}

.navbarcontainer{
  z-index: 111111111111111111;
}

.container1 {
  display: flex;
  /* height: 80vh; */
  /* justify-content: space-evenly; */
  /* padding-top: 80px; */
  background-image: url("../assets/about-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 90vh;
  
}

.fade_banner{
  width: 60%;
  height: 0;
  position: absolute;
  left: 0px;
  top: 0px;
  background-image: linear-gradient(
    270deg,
    transparent,
    rgba(37,37,37,0.71),
    #111
  );
  margin-top: 10vh;
 
}

.container1shadow{
  width: 10px;
}
.container1_img{
  position: absolute;
  /* z-index: -1; */
  width: 100vw;
   height: 90vh;

}

.container1left {
  /* width: 350px; */
  width: 50vw;
  padding-left: 5vw;
  z-index: 11;
  /* background-color: aqua; */
  /* align-items: center; */
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.container1rightimg {
  width: 400px;
  border-radius: 200px;
}

.container1left {
  margin-top: 30px;
}

.container1left h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 80px;
  /* background-color: azure; */
  color:rgb(219, 219, 219);
  margin-bottom: 20px;
}

.container1left p {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 17px;
  margin-top: 15px;
  color: rgb(219, 219, 219);
}

@media only screen and (max-width: 1000px) {
  .container1left h1 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 50px;
  }

  .container1left p {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 13px;
    margin-top: 15px;
  }
}

.container1left button {
  background-color: red;
  padding: 7px;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 10px;
  border: none;
  color: white;
  margin-top: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  border-radius: 10px;
}

/* Container2 */

.container2img {
  width: 150px;
  border-radius: 150px;
}

.container2 {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  /* background-color: aqua; */
  align-items: center;
  height: 350px;
}

.container2imagecontainer {
  align-items: center;
  justify-content: center;
  /* background-color: blue; */
  text-align: center;
}

.container2textcontainer {
  width: 350px;
}

.container2textcontainer h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 25px;
}

.container2textcontainer p {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 13px;
}

.container2textleftcontainer h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 18px;
}

.container2textleftcontainer h2 {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
}

/* Container3 */

.container3img {
  width: 150px;
  border-radius: 150px;
  background-color: blue;
  
  height: 150px;
}

.container3 {
  display: flex;
  flex-direction: row-reverse;

  justify-content: space-evenly;
  /* background-color: aqua; */
  align-items: center;
  height: 350px;
}

.container3imagecontainer {
  align-items: center;
  justify-content: center;
  /* background-color: blue; */
  text-align: center;
}

.container3textcontainer {
  width: 350px;
}

.container3textcontainer h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 25px;
}

.container3textcontainer p {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 13px;
}

.container3textleftcontainer h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 18px;
}

.container3textleftcontainer h2 {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
}

@media screen and (max-width: 750px) {
  .container1 {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    height: 650px;
    /* margin-top: -70px; */
  }
  .container1rightimg {
    width: 250px;
    border-radius: 200px;
  }
  .container2 {
    display: flex;
    flex-direction: column;
    /* background-color: red; */
    height: 500px;
  }

  .container3rightimg {
    width: 250px;
    border-radius: 200px;
  }
  .container3 {
    display: flex;
    flex-direction: column;
    /* background-color: red; */
    height: 500px;
  }
}

/* Teams */

.box_container {
  /* width: 90%; */
  /* border: 1px solid black; */
  display: grid;
  grid-template-rows: 250px 250px;
  grid-template-columns: 280px 280px;
  grid-row-gap: 50px;
  grid-column-gap: 30px;
  margin-top: 10px;
  /* background-color: aqua; */
  justify-content: center;
  /* align-items: center; */
  margin-top: 30px;
  padding-bottom: 100px;
}

.grid2 {
  display: grid;
  grid-template-rows: 150px;
  grid-template-columns: 150px 150px 150px 150px;
  grid-row-gap: 50px;
  grid-column-gap: 30px;
  /* margin-top: 50px; */
  padding-bottom: 100px;
  /* background-color: yellow; */
}

.grid2 h1 {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  color: white;
  font-weight: 400;
}

/* .grid2_box1:hover {
  background-color: rgb(62, 62, 238);
  cursor: pointer;
}

.grid2_box2:hover {
  background-color: rgb(62, 62, 238);
  cursor: pointer;
}

.grid2_box3:hover {
  background-color: rgb(62, 62, 238);
  cursor: pointer;
}

.grid2_box4:hover {
  background-color: rgb(62, 62, 238);
  cursor: pointer;
}

.grid2_box5:hover {
  background-color: rgb(62, 62, 238);
  cursor: pointer;
} */
.grid2_box1 {
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.grid2_box2 {
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.grid2_box3 {
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.grid2_box4 {
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.grid2_box5 {
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.grid2 a {
  background-color: rgb(39, 36, 36);
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.teams {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  color: white;
  /* margin-top: 60px; */
  text-align: center;
  color: black;
  justify-content: center;
  align-items: center;
  display: flex;
  font-weight: 500;
  padding-bottom: 30px;
}

.core_heading h1 {
  font-weight: 400;
}
.box1 {
  /* background-color: aqua; */
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
  color: black;
}

.box2 {
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
}

.box3 {
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
}

.box4 {
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
}

.box5 {
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
}

.box6 {
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
}

.box7 {
  background-color: rgb(39, 36, 36);
  border-radius: 20px;
}

.box8 {
  background-color: rgb(39, 36, 36);
  border-radius: 20px;
}

.box9 {
  background-color: rgb(39, 36, 36);
  border-radius: 20px;
}

.box10 {
  background-color: rgb(51, 49, 49);
  border-radius: 20px;
}

.box11 {
  background-color: rgb(51, 49, 49);
  border-radius: 20px;
}

.box12 {
  background-color: rgb(51, 49, 49);
  border-radius: 20px;
}

.image_container {
  /* width: 120px;
  height: 120px; */
  border: 1px solid rgb(228, 217, 217);
  justify-content: center;
  align-items: center;
  display: flex;
  width: 120px;
  height: 120px;
  border-radius: 50px;
  background: rgb(238, 235, 233);
}

.image_container img {
  width: 120px;
  height: 120px;
  border-radius: 50px;
  background-color: rgb(255, 255, 255);
}

.box_information {
  font-family: "Poppins", sans-serif;
  align-items: center;
  display: flex;
  flex-direction: column;
  /* background-color: rgb(115, 27, 230); */
}

.image_container {
  margin-top: 20px;
}
.box_information h1 {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  color: white;
  margin-top: 25px;
  color: black;
}

.box_information h2 {
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  /* margin-top: -10px; */
  color: rgb(209, 207, 207);
  font-weight: 400;
  color: black;
}

.box_information p {
  font-family: "Poppins", sans-serif;
  font-size: 11px;
  font-weight: 500;
  margin-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  color: rgb(209, 207, 207);
  font-weight: 400;
  color: black;
  /* margin-top: 5px; */
}

.logo_container {
  padding-top: 18px;
}

.logo_container img {
  width: 25px;
  height: 25px;
  margin-left: 10px;
  margin-right: 10px;
}

.teams h1 {
  font-family: "Poppins", sans-serif;
  font-size: 25px;
  font-weight: 500;
  color: rgb(0, 0, 0);
  border-bottom: 3px solid black;
  padding-bottom: 5px;

  width: fit-content;
  text-align: center;
}

.teams_heading {
  margin-top: 50px;
  margin-bottom: 10px;
}

.box1:hover {
  cursor: pointer;
}

.box2:hover {
  /* background-color: rgb(62, 62, 238); */
  cursor: pointer;
}

.box3:hover {
  /* background-color: rgb(62, 62, 238); */
  cursor: pointer;
}

.box4:hover {
  /* background-color: rgb(62, 62, 238); */
  cursor: pointer;
}

.box5:hover {
  /* background-color: rgb(62, 62, 238); */
  cursor: pointer;
}

.box6:hover {
  /* background-color: rgb(62, 62, 238); */
  cursor: pointer;
}

.box7:hover {
  /* background-color: rgb(62, 62, 238); */
  cursor: pointer;
}

.box8:hover {
  /* background-color: rgb(62, 62, 238); */
  cursor: pointer;
}

.box9:hover {
  /* background-color: rgb(62, 62, 238); */
  cursor: pointer;
}

.grid2 h1 a {
  text-decoration: none;
  color: white;
}

.grid2 a {
  text-decoration: none;
}

.grid2 a:hover {
  text-decoration: none;
  background-color: rgb(62, 62, 238);
}

.grid2 a {
  background-color: rgb(51, 49, 49);
}

@media only screen and (max-width: 910px) {
  .box_container {
    display: grid;
    grid-template-rows: 350px 350px 350px 350px;
    grid-template-columns: 280px 280px;
    grid-row-gap: 50px;
    grid-column-gap: 30px;
    margin-top: 10px;
  }

  .grid2 {
    display: grid;
    grid-template-rows: 150px 150px;
    grid-template-columns: 150px 150px;
    grid-row-gap: 50px;
    grid-column-gap: 30px;
    /* margin-top: 50px; */
    padding-bottom: 100px;
    /* background-color: yellow; */
  }
}

@media only screen and (max-width: 500px) {
  .box_container {
    display: grid;
    grid-template-rows: 350px;
    grid-template-columns: 280px 280px 280px 280px;
    /* grid-row-gap: 30px; */
    grid-column-gap: 10px;
    /* margin-top: 10px; */
    overflow-x: scroll;
    width: 100%;
    /* background-color: blueviolet; */
    /* align-items: flex-start; */
    justify-content: flex-start;
    align-self: center;
    padding-left: 5%;
    padding-right: 5%;
    height: 260px;
    overflow-y: hidden;
  }
  ::-webkit-scrollbar {
    display: none;
  }

  .box_container_main {
    justify-content: center;
    align-items: center;
    display: flex;
    padding-bottom: 70px;
  }
}

/* Footer */

@media only screen and (max-width: 700px) {
  .container1left h1 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 30px;
    margin-top: -10px;
  }

  .container1left p {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 15px;
    margin-top: -15px;
  }
  .container1left {
    width: 90vw;
    /* margin-top: -50px; */
  }

  .container1 {
    display: flex;
    height: 80vh;
    /* justify-content: space-evenly; */
    /* padding-top: 80px; */
    background-image: url("https://img.freepik.com/free-photo/vintage-old-rustic-cutlery-dark_1220-4886.jpg?size=626&ext=jpg");
    background-repeat: no-repeat;
    background-size: 100vw 90%;
  }
}
