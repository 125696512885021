@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500&display=swap");

.container_main {
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: #fafbca;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background-image: url("https://images.unsplash.com/photo-1633383718081-22ac93e3db65?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=758&q=80");
  background-repeat: no-repeat;
  background-size: 100vw 100%;
  /* overflow-y: hidden; */
  overflow-x: hidden;
}

.nav_container {
  width: 100%;
}

.ourmenu_container h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 25px;
  border-bottom: 3px solid rgb(255, 96, 22);
  color: white;
}

.ourmenu_container {
  margin-top: 30px;
}
/* Teams */

.box_container {
  /* width: 90%; */
  /* border: 1px solid black; */
  display: grid;
  grid-template-rows: 230px 230px;
  grid-template-columns: 230px 230px 230px;
  grid-row-gap: 50px;
  grid-column-gap: 30px;
  margin-top: 10px;
  /* background-color: aqua; */
  justify-content: center;
  /* align-items: center; */
  margin-top: 30px;
  padding-bottom: 100px;
}

.grid2 {
  display: grid;
  grid-template-rows: 150px;
  grid-template-columns: 150px 150px 150px 150px;
  grid-row-gap: 50px;
  grid-column-gap: 30px;
  /* margin-top: 50px; */
  padding-bottom: 100px;
  /* background-color: yellow; */
}

.grid2 h1 {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  color: white;
  font-weight: 400;
}

/* .grid2_box1:hover {
    background-color: rgb(62, 62, 238);
    cursor: pointer;
  }
  
  .grid2_box2:hover {
    background-color: rgb(62, 62, 238);
    cursor: pointer;
  }
  
  .grid2_box3:hover {
    background-color: rgb(62, 62, 238);
    cursor: pointer;
  }
  
  .grid2_box4:hover {
    background-color: rgb(62, 62, 238);
    cursor: pointer;
  }
  
  .grid2_box5:hover {
    background-color: rgb(62, 62, 238);
    cursor: pointer;
  } */
.grid2_box1 {
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.grid2_box2 {
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.grid2_box3 {
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.grid2_box4 {
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.grid2_box5 {
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.grid2 a {
  background-color: rgb(39, 36, 36);
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.teams {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  color: white;
  /* margin-top: 60px; */
  text-align: center;
  color: black;
  justify-content: center;
  align-items: center;
  display: flex;
  font-weight: 500;
  padding-bottom: 30px;
}

.core_heading h1 {
  font-weight: 400;
}
.box1 {
  /* background-color: aqua; */
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
  color: black;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  text-decoration: none;
}

.box1:hover {
  background-color: rgb(248, 245, 245);
}

.box2:hover {
  background-color: rgb(248, 245, 245);
}

.box3:hover {
  background-color: rgb(248, 245, 245);
}

.box4:hover {
  background-color: rgb(248, 245, 245);
}

.box5:hover {
  background-color: rgb(248, 245, 245);
}
.box2 {
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  text-decoration: none;
}

.box3 {
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  text-decoration: none;
}

.box4 {
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  text-decoration: none;
}

.box5 {
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  text-decoration: none;
}

.box6 {
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
}

.box7 {
  background-color: rgb(39, 36, 36);
  border-radius: 20px;
}

.box8 {
  background-color: rgb(39, 36, 36);
  border-radius: 20px;
}

.box9 {
  background-color: rgb(39, 36, 36);
  border-radius: 20px;
}

.box10 {
  background-color: rgb(51, 49, 49);
  border-radius: 20px;
}

.box11 {
  background-color: rgb(51, 49, 49);
  border-radius: 20px;
}

.box12 {
  background-color: rgb(51, 49, 49);
  border-radius: 20px;
}

.image_container {
  /* width: 120px;
    height: 120px; */
    
  border: 1px solid rgb(228, 217, 217);
  justify-content: center;
  align-items: center;
  display: flex;
  /* width: 120px; */
  height: 120px;
  border-radius: 50px;
  background: rgb(238, 235, 233);
}

.image_container img {
  width: 230px;
  /* width: 100%; */
  height: 161px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: black;
}

.box_information {
  font-family: "Poppins", sans-serif;
  align-items: center;
  display: flex;
  flex-direction: column;
  /* background-color: rgb(115, 27, 230); */
}

.image_container {
  margin-top: 20px;
}
.box_information h1 {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  color: white;
  margin-top: 25px;
  color: black;
}

.box_information h2 {
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  /* margin-top: -10px; */
  color: rgb(209, 207, 207);
  font-weight: 400;
  color: black;
}

.box_information p {
  font-family: "Poppins", sans-serif;
  font-size: 11px;
  font-weight: 500;
  margin-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  color: rgb(209, 207, 207);
  font-weight: 400;
  color: black;
  /* margin-top: 5px; */
}

.logo_container {
  padding-top: 18px;
}

.logo_container img {
  width: 25px;
  height: 25px;
  margin-left: 10px;
  margin-right: 10px;
}

.teams h1 {
  font-family: "Poppins", sans-serif;
  font-size: 25px;
  font-weight: 500;
  color: rgb(0, 0, 0);
  border-bottom: 3px solid black;
  padding-bottom: 5px;

  width: fit-content;
  text-align: center;
}

.teams_heading {
  margin-top: 50px;
  margin-bottom: 10px;
}

.box1:hover {
  cursor: pointer;
}

.box2:hover {
  /* background-color: rgb(62, 62, 238); */
  cursor: pointer;
}

.box3:hover {
  /* background-color: rgb(62, 62, 238); */
  cursor: pointer;
}

.box4:hover {
  /* background-color: rgb(62, 62, 238); */
  cursor: pointer;
}

.box5:hover {
  /* background-color: rgb(62, 62, 238); */
  cursor: pointer;
}

.box6:hover {
  /* background-color: rgb(62, 62, 238); */
  cursor: pointer;
}

.box7:hover {
  /* background-color: rgb(62, 62, 238); */
  cursor: pointer;
}

.box8:hover {
  /* background-color: rgb(62, 62, 238); */
  cursor: pointer;
}

.box9:hover {
  /* background-color: rgb(62, 62, 238); */
  cursor: pointer;
}

.grid2 h1 a {
  text-decoration: none;
  color: white;
}

.grid2 a {
  text-decoration: none;
}

.grid2 a:hover {
  text-decoration: none;
  background-color: rgb(62, 62, 238);
}

.grid2 a {
  background-color: rgb(51, 49, 49);
}

@media only screen and (max-width: 910px) {
  .box_container {
    display: grid;
    grid-template-rows: 230px 230px 230px;
    grid-template-columns: 230px 230px;
    grid-row-gap: 50px;
    grid-column-gap: 30px;
    margin-top: 10px;
  }

  .grid2 {
    display: grid;
    grid-template-rows: 150px 150px;
    grid-template-columns: 150px 150px;
    grid-row-gap: 50px;
    grid-column-gap: 30px;
    /* margin-top: 50px; */
    /* padding-bottom: 100px; */
    /* background-color: yellow; */
  }
  .box_container_main {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 500px) {
  .box_container {
    /* display: grid;
    grid-template-rows: 150px 150px 150px 150px 150px;
    grid-template-columns: 150px;

    grid-column-gap: 10px;

    justify-content: flex-start;

    padding-left: 5%;
    padding-right: 5%;
    height: 260px;
    overflow-y: hidden; */

    grid-template-rows: 230px 230px;
    grid-template-columns: 230px;
  }
  ::-webkit-scrollbar {
    display: none;
  }

  /* .box_container_main {
    justify-content: center;
    align-items: center;
    display: flex;
    padding-bottom: 70px;
  } */
}

/* Footer */

@media only screen and (max-width: 700px) {
  .container1left h1 {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 30px;
    margin-top: -10px;
  }

  .container1left p {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 15px;
    margin-top: -15px;
  }
  .container1left {
    width: 90vw;
    /* margin-top: -50px; */
  }

  .container1 {
    display: flex;
    height: 80vh;
    /* justify-content: space-evenly; */
    /* padding-top: 80px; */
    background-image: url("https://images.pexels.com/photos/349609/pexels-photo-349609.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1");
    background-repeat: no-repeat;
    background-size: 100vw 90%;
  }
  .box_container_main {
    margin-top: 20px;
  }
}

.footer {
  width: 100%;
  margin-top: 50px;
}

@media only screen and (max-width: 1000px) {
  .container_main {
    background-image: none;
  }
}
