@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500&display=swap");
.homecontainer {
  overflow-x: hidden;
}
.container1 {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  height: 80vh;
  justify-content: space-evenly;
  align-items: center;
  overflow: hidden;
}

.container1left {
  /* width: 50vw; */
  /* margin-top: -80px; */
}
.container1right {
  /* background-color: yellow; */
  justify-content: center;
  align-items: center;
  display: flex;
}

.container1rightimg {
  width: 500px;
  margin-top: 10px;
}
.container1left h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 70px;
}

.container1left h2 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 70px;
  margin-top: -20px;
}

.container1left h3 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: red;
  margin-top: -20px;
  font-size: 60px;
}

.container1left p {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: rgb(0, 0, 0);
  /* margin-top: -20px; */
  font-size: 18px;
  width: 350px;
}

.container1left button {
  background-color: red;
  padding: 7px;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 10px;
  border: none;
  color: white;
  margin-top: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  /* border-radius: 10px; */
}

/* Container2 */

.container2 {
  display: flex;
  flex-direction: row;
  width: 100vw;
  /* background-color: yellow; */
  justify-content: space-between;
  align-items: center;
}

.container2text {
  width: 40vw;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  /* background-color: yellow; */
  margin-right: 100px;
}

.container2text h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 45px;
}

.container2text h2 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 17px;
  margin-top: 15px;
}
.container2text h3 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 17px;
  margin-top: 20px;
}

.container2img2 {
  height: 250px;
  margin-right: 5vw;
}

.container3 {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 50vh;
}

.container3text {
  width: 40vw;
  /* margin-left: 10vw; */
}

.container3img {
  width: 220px;
  /* height: 200px; */
  /* margin-right: 10vw; */
}

.watchimg {
  width: 170px;
  /* height: 200px; */
}

.stepscontainer {
  /* height: 300px; */
  /* background-color: blue; */
  align-items: center;
  /* justify-content: center; */
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  padding-bottom: 50px;
}

.stepscontainer h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 35px;
  margin-top: 15px;
}
.stepscards {
  display: flex;
  /* margin: 20px; */
  margin-top: 20px;
}

.stepscard1:hover {
}
.stepscard1 {
  width: 200px;
  height: 250px;
  background-color: white;
  margin: 10px;
  align-items: center;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  text-align: center;
  justify-content: center;
  /* background: blue; */
  display: flex;
  flex-direction: column;
}

.stepscard1 h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 20px;
  color: rgb(27, 26, 26);
  width: 90%;
  /* background: red; */
}
.stepscard1img {
  width: 200px;
  margin-top: 15px;
}

.stepscard2img {
  width: 130px;
  margin-top: 15px;
}

.stepscard3img {
  width: 130px;
  margin-top: 15px;
}
.stepscard2 h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 20px;
  width: 90%;
  /* background: red; */
  text-align: center;
}
.stepscard2 {
  width: 200px;
  height: 250px;
  background-color: white;
  margin: 10px;
  align-items: center;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  justify-content: center;
  /* background: blue; */
  display: flex;
  flex-direction: column;
}

.stepscard3 {
  width: 200px;
  height: 250px;
  background-color: white;
  margin: 10px;
  align-items: center;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  justify-content: center;
  /* background: blue; */
  display: flex;
  flex-direction: column;
}

.stepscard3 h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 20px;
  /* background: red; */
  text-align: center;
  width: 90%;
}

.stepscard4 h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 20px;
  width: 90%;
  width: 90%;
  /* background: red; */
  text-align: center;
}
.stepscard4 {
  width: 200px;
  height: 250px;
  background-color: white;
  margin: 10px;
  align-items: center;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  justify-content: center;
  /* background: blue; */
  display: flex;
  flex-direction: column;
}

.stepscard4img {
  width: 130px;
  margin-top: 15px;
}

.container3text h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 40px;
  color: rgb(40, 10, 173);
}

.container3text h2 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 17px;
  font-size: 30px;
}

.container3text h3 {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  margin-top: 5px;
}

.container4 {
  height: 280px;
  background-color: #000000;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 20px;
  justify-content: space-evenly;
  align-items: center;
  display: flex;
  flex-direction: row;
}

.container4 h1 {
  /* margin-top: -20px; */
  color: white;
  letter-spacing: 0px;
  font-weight: 500;
}

.container4inputcontainer {
  margin-top: 20px;
}

.container4inputcontainer input {
  padding: 10px;
  border: none;
  border-radius: 5px;
  font-family: "Poppins", sans-serif;
  outline: none;
  font-weight: 500;
  font-size: 15px;
  width: 350px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.container4inputcontainer button {
  padding: 10px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  font-family: "Poppins", sans-serif;
  outline: none;
  font-weight: 500;
  margin-left: 10px;
  background-color: white;
}

.container4inputcontainer button:hover {
  background-color: rgb(219, 21, 21);
  color: white;
}

.newsletterrightcontainer {
  /* background-color: rgb(250, 43, 43); */
}

.container4img {
  width: 150px;
}

.container5img {
  width: 280px;
}

@media screen and (max-width: 1000px) {
  .container1 {
    /* background-color: lavender; */
    display: flex;
    flex-direction: column-reverse;
    height: 700px;
  }

  .container1rightimg {
    width: 280px;
    margin-top: 10px;
  }

  .container1left h1 {
    font-size: 30px;
  }
  .container1left h2 {
    font-size: 30px;
    margin-top: 3px;
  }
  .container1left h3 {
    font-size: 30px;
    margin-top: 3px;
  }
  .container1left p {
    font-size: 20px;
  }
  .container2 {
    display: flex;
    flex-direction: column;
  }
  .container2text {
    width: 90vw;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    /* background-color: yellow; */
    margin-right: 0px;
  }
  .container2img1 {
    width: 250px;
    left: 0px;
    /* position: absolute; */
    display: none;
  }
  .container2img2 {
    height: 100px;
    /* margin-right: 5vw; */
    margin-top: 10px;
  }
  .stepscards {
    display: flex;
    /* margin: 20px; */
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* background-color: yellow; */
    align-items: center;
  }
  .stepscontainerh1 {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 25px;
    margin-top: 15px;
    text-align: center;
  }

  .container3 {
    flex-direction: column-reverse;
    display: flex;
    /* background-color: yellowgreen; */
    height: 500px;
  }
  .container3text {
    /* height: 100vh; */
    width: 90vw;
  }
  .container3img {
    width: 150px;
    height: 150px;
    /* margin-right: 10vw; */
  }

  .container3 {
    flex-direction: column-reverse;
    display: flex;
    /* background-color: yellowgreen; */
    height: 500px;
  }

  .container4 {
    flex-direction: column;
    display: flex;
    /* background-color: yellowgreen; */
    height: 500px;
  }

  .container4inputcontainer {
    /* background-color: red; */
    justify-content: center;
    display: flex;
    align-items: center;
  }
  .container4inputcontainer input {
    width: 150px;
  }
  .newsletterrightcontainer {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .newsletterh1 {
    font-size: 25px;
  }
  .container4 {
    height: 280px;
  }
  .container4img {
    width: 130px;
  }
}
