@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap");
.feedback_container {
  align-items: center;
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  /* padding: 10px; */
  margin: 0px;
  width: 100vw;
  height: 100vh;
  /* padding-bottom: 100px; */
  padding: 0px;
  overflow-x: hidden;
}

.navbarcontainer {
  width: 100%;
  background-color: black;
  /* height: 100px; */
}

.containerheading h1 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: black;
}

.inputcontainer1 input {
  padding: 10px;
  outline: none;
  font-size: 15px;
  border-radius: 5px;
  margin: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 300;

  border: 1px solid rgb(153, 148, 148);
}

.inputcontainer1 {
  padding-top: 25px;
}

.inputcontainer2 input {
  padding: 10px;
  outline: none;
  font-size: 15px;
  border-radius: 5px;
  margin: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  border: 1px solid rgb(153, 148, 148);
}

.inputcontainer2 {
  padding-top: 10px;
}

.inputcontainer1 input {
  /* padding-left: 10px; */

  padding-top: 10px;
  /* padding-bottom: 150px; */
  outline: none;
  font-size: 15px;
  /* width: 100%; */
  /* border-radius: 5px; */
  /* margin: 10px; */
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  width: 440px;
  border: 1px solid rgb(153, 148, 148);
}

.inputcontainer3 textarea {
  padding-left: 10px;
  /* padding-right: 240px; */
  padding-top: 10px;
  padding-bottom: 150px;
  outline: none;
  font-size: 15px;
  border-radius: 5px;
  margin: 10px;
  width: 450px;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  border: 1px solid rgb(153, 148, 148);
}

.inputcontainer3 {
  padding-top: 10px;
}

.containerheading h1 {
  margin-top: 30px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: rgb(0, 0, 0);
  margin-top: 30px;
}

.button {
  margin-top: 20px;
}
.button button {
  width: 230px;
  padding: 10px;
  border-radius: 10px;
  outline: none;
  border: none;
  border-radius: 20px;
  background-color: #006aff;
  color: #ffff;
  font-family: "Poppins", sans-serif;
  font-weight: 700;

  padding-left: 15px;
  padding-right: 15px;
}

.button button:hover {
  cursor: pointer;
}

.containerheading {
  margin-top: 30px;
  /* background-color: aqua; */
}

/* Footer */

.navbar_container h1 {
  color: white;
}
.navbar_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 10vh;
  background-color: #0a0a0a;
  /* border-bottom: 1px solid rgb(43, 41, 41); */
  border-bottom: 1px solid rgb(43, 41, 41);
}

.navbar_container ul {
  display: flex;
  padding-right: 100px;
}

.navbar_container ul li {
  margin-right: 50px;
  list-style: none;
  color: white;
  font-family: "Lato", sans-serif;
  padding: 10px;
  font-size: 16px;
  font-weight: 400;
}

.navbar_container ul li a {
  list-style: none;
  color: white;
  font-family: "Lato", sans-serif;
  padding: 10px;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
}

.navbar_container ul li:hover {
  cursor: pointer;
  background: rgb(37, 37, 37);
  border-radius: 15px;
}

.navbar_container img {
  width: 80px;
  margin-left: 50px;
}

.container1_txt h3 {
  max-width: 600px;
  color: rgb(255, 255, 255);

  font-weight: 400;
  font-size: 13px;
  font-family: "Poppins", sans-serif;
}

.footer_container2 {
  display: flex;
  justify-content: space-evenly;
}
.footer_container2 ul li {
  list-style: none;
}

.footer {
  height: 70vh;
  position: relative;
  bottom: 0px;

  background: #2374bf;
}

.footer_container2_main {
  /* background-color: azure; */
  /* align-items: flex-start; */
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.footer_container2_main ul {
  /* align-self: flex-start; */
  /* position: relative; */
  background-color: blueviolet;
  /* width: fit-content; */
  justify-content: start;
  align-content: flex-start;
  display: flex;
  flex-direction: column;
}
.footer_container2_main ul li {
  /* align-self: flex-start; */

  display: flex;
  flex-direction: column;
  /* background-color: brown; */
  left: 0px;
}
.footer_container2_main li {
  list-style: none;
}

.heading {
  font-size: 35px;
  /* color: aquamarine; */
}

.footer_logo_container {
  /* background: #501150; */
  align-items: center;
  display: flex;
  padding-top: 30px;
  padding-bottom: 10px;
  justify-content: center;
}

.footer_container2_main .lists {
  margin-top: -10px;
  font-family: "Lato", sans-serif;
  font-weight: lighter;
  color: white;
}

.footer_container2_main .lists li {
  margin-top: 5px;
  max-width: 250px;
  font-weight: lighter;
}
.footer_container2_main h1 {
  font-family: "Lato", sans-serif;
  color: white;
  font-weight: 700;
  font-size: 30px;
}

.links li {
  border-bottom: 1px solid #2374bf;
  width: fit-content;
}
.links li:hover {
  cursor: pointer;
  border-bottom: 1px solid rgb(255, 255, 255);
}

.connect_with_us {
  /* background: yellow; */
  padding-top: 60px;
}
.connect_with_us h1 {
  font-family: "Lato", sans-serif;
  color: white;
  font-weight: 400;
  font-size: 20px;
  text-align: center;
}

.copyright {
  padding-top: 20px;
  /* background: yellow; */
}

.copyright h1 {
  font-family: "Lato", sans-serif;
  color: white;
  font-weight: 400;
  font-size: 15px;
  text-align: center;
  margin-top: -10px;
}

.icons {
  /* background: blueviolet; */
  display: flex;
  position: relative;
  margin-top: -10px;
  justify-content: center;
}
.icons i {
  margin: 10px;
  font-size: 25px;
  color: white;
}
.fa-check-circle {
  color: #ffffff;
}

.spotify {
  margin-left: 202px;
  width: 1115px;
}

@media screen and (max-width: 450px) {
  /* .inputcontainer1 {
    display: flex;
    flex-direction: column;
    width: 90vw;
  }
  .inputcontainer2 {
    display: flex;
    flex-direction: column;
    width: 90vw;
  }
  .inputcontainer3 {
    display: flex;
    flex-direction: column;
    width: 90vw;
  } */

  .inputcontainer1 input {
    width: 77vw;
  }

  .inputcontainer3 textarea {
    width: 80vw;
  }

  .containerheading h1 {
    margin-top: 30px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    color: rgb(0, 0, 0);
    margin-top: 25px;
    text-align: center;
    font-size: 20px;
  }
}
